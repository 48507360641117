<template>
  <ValidationObserver class="register_photo" v-slot="{ handleSubmit }">
    <time-line :active="6"></time-line>

    <div class="top_card">
      <router-link :to="{ name: 'home', params: { lang: 'uz' } }">
        <img src="@/assets/uzum-logo.svg" alt="" />
      </router-link>

      <h2>{{ $localize('register-photo')['confidantTitle'] }}</h2>

      <p class="example">{{ $localize('register-photo')['confidantText'] }}</p>
    </div>

    <div class="form_input">
      <confidant-card
        :title="$localize('register-photo')['confidantCardText']"
        :index="0"
        :secondGuarantIndex="1"
        :personalPhone="userInfo.phone"
        :has-remove-icon="false"
        class="confidant-card"
        @onChangeName="fio"
        @onChangePhoneNumber="phone"
      />
      <confidant-card
        :title="$localize('register-photo')['confidantCardText']"
        :index="1"
        :secondGuarantIndex="0"
        :personalPhone="userInfo.phone"
        :has-remove-icon="false"
        class="confidant-card"
        @onChangeName="fio"
        @onChangePhoneNumber="phone"
      />
    </div>

    <div class="form_input btn-bottom">
      <button
        :disabled="loading"
        class="P_button"
        @click="handleSubmit(submit)"
        :class="{ 'btn-loading': loading }"
      >
        {{ $localize('button')['continue'] }}
      </button>
    </div>
  </ValidationObserver>
</template>

<script>
import Cookies from 'js-cookie';

import TimeLine from '../../components/mobile/bars/TimeLine';
import ConfidantCard from '../../components/cards/ConfidantCard';

import { ValidationObserver } from 'vee-validate';
import { createNamespacedHelpers } from 'vuex';
import BuyerService from '@/services/buyer.service';

const { mapGetters: mapBuyerGetters } = createNamespacedHelpers('buyers');

export default {
  name: 'ConfidantPage',
  components: {
    ConfidantCard,
    TimeLine,
    ValidationObserver,
  },
  computed: {
    ...mapBuyerGetters(['userInfo']),
    firstValid() {
      return this.confidant[0].fio !== '' && this.confidant[0].phone !== '';
    },
    secondValid() {
      return this.confidant[1].fio !== '' && this.confidant[1].phone !== '';
    },
  },
  data: () => ({
    loading: false,
    api_token: Cookies.get('token'),
    isValid: false,
    confidant: [
      { fio: '', phone: '' },
      { fio: '', phone: '' },
    ],
  }),
  methods: {
    fio({ name, index }) {
      const confidantName = name;
      if (confidantName.length >= 5) {
        this.confidant[index].fio = confidantName;
      } else {
        this.confidant[index].fio = '';
      }
    },

    phone({ phoneNumber, index }) {
      this.confidant[index].phone = phoneNumber;
    },

    async submit() {
      if (this.firstValid && this.secondValid) {
        this.loading = true;

        await this.addGuarant(0);
        await this.addGuarant(1);

      } else {
        this.loading = false;
        this.$toastError(this.$localize('error')['empty']);
      }
    },

    async addGuarant(number) {
      try {
        const { data } = await this.$axios.post('/buyer/add-guarant', {
          name: this.confidant[number].fio,
          phone: this.confidant[number].phone,
          buyer_id: this.userInfo.id,
        });

        if (data.status === 'success') {
          await this.$store.dispatch('buyers/userStatus');
          this.loading = false;
          localStorage.removeItem('user_number');
          await this.$toasted.clear();
          await this.$router.push({ name: 'loading' }).catch(() => {
          });
        } else {
          this.loading = false;
          data.response.errors.forEach((error) => this.$toastError(error));
        }
      } catch (e) {
        this.loading = false;
        this.$toastError(e.message);
      }
    },
  },
  async created() {
    await BuyerService.detail();
  },
};
</script>

<style lang="scss" scoped>
  .top_card {
    padding-top: 17%;

    img {
      margin-bottom: 56px;
    }

    h2 {
      font-weight: 900;
      font-size: 40px;
      line-height: 40px;
      color: $black;
      margin-bottom: 1rem;
    }

    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: $black;
      margin-bottom: 24px;
    }
  }

  button {
    max-width: 384px;
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    height: 100%;

    &.disabled {
      cursor: not-allowed;
    }
  }

  .form_input {
    display: flex;

    .confidant-card {
      margin-right: 2rem;

      @media (max-width: $mobile) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    &.btn-bottom {
      margin-top: 2rem;
    }
  }

  button.P_button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background: $main;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    color: $white;

    border-radius: 8px;
    padding: 16px 24px;

    font-weight: 600;
    font-size: 15px;
    line-height: 111.63%;

    transition: 0.2s;
    cursor: pointer;

    &:hover {
      background: $main-light;
    }
  }

  .btn-loading {
    position: relative;
    pointer-events: none;
    color: transparent !important;

    &:after {
      animation: spinAround 500ms infinite linear;
      border: 2px solid $white;
      border-radius: 50%;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
    }
  }

  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 1199px) {
    .register_photo {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .top_card {
        padding-top: 0;
      }

      .form_input:nth-child(4) {
        width: 100%;
        justify-content: center;
      }

      button {
        max-width: 332px;
      }
    }
  }

  @media (max-width: 575px) {
    .register_photo {
      height: auto;
      justify-content: unset;
      padding-bottom: 100px;
      padding-top: 30px;
    }

    .top_card img {
      display: none;
    }

    .top_card h2 {
      font-weight: 900;
      font-size: 24px;
      line-height: 40px;
      text-align: center;
      color: $black;
      margin-bottom: 8px;
    }

    .top_card p {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 16px;
    }

    .form_input {
      width: 100%;
      flex-direction: column;

      &.btn-bottom {
        button {
          margin-bottom: 0;
        }
      }
    }

    .register_photo button {
      max-width: 100%;
    }

    button.P_button {
      margin-bottom: 32px;
    }
  }
</style>
