<template>
  <div class="card">
      <span v-if="hasRemoveIcon" class="absolute_times" @click="removeCard(index)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
        <path
          d="M17 7L7 17M7 7L17 17"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        </svg>
      </span>

    <div class="title">
      {{ title }}
      {{ index + 1 }}
    </div>

    <div class="form-card">
      <label for="name" class="form-label">
        {{ $localize('orders-form')['fio-placeholder'] }}
      </label>
      <ValidationProvider
        :name="`guarantName-${index}`"
        :rules="`required|min:5`"
        v-slot="{ errors }"
      >
        <input
          v-model="name"
          type="text"
          id="name"
          class="form-control"
          :placeholder="$localize('orders-form')['fio-placeholder']"
          @change="fio(index)"
          @keypress="isLetter($event)"
        />
        <div class='error'>{{ errors[0] }}</div>
      </ValidationProvider>
    </div>

    <div class="form-card">
      <label for="phone" class="form-label">
        {{ $localize('orders-form')['number-placeholder'] }}
      </label>
      <ValidationProvider
        :name="`guarantPhone-${index}`"
        :rules="`required|phone|guarantPersonalPhone:${personalPhone}|guarantPhoneMatch:@guarantPhone-${secondGuarantIndex}`"
        v-slot="{ errors }"
      >
        <input
          v-model="phoneNumber"
          type="tel"
          v-mask="'+998 (##) ###-##-##'"
          :placeholder="$localize('orders-form')['number-placeholder']"
          id="phone"
          class="form-control"
          @change="phone(index)"
        />
        <div class='error'>{{ errors[0] }}</div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'ConfidantCard',
  props: {
    title: VueTypes.string,
    index: VueTypes.number.def(0),
    secondGuarantIndex: VueTypes.number.def(1),
    hasRemoveIcon: VueTypes.bool.def(true),
    personalPhone: VueTypes.string,
  },
  data() {
    return {
      name: '',
      phoneNumber: '',
    };
  },
  watch: {
    name(val) {
      this.name = val.replace(/\s{2,}/g, ' ');
    },
  },
  methods: {
    fio(index) {
      this.$emit('onChangeName', { name: this.name.trim(), index });
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z, ^А-ЯЁа-яё]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    phone(index) {
      this.$emit('onChangePhoneNumber', { phoneNumber: this.phoneNumber, index });
    },
    removeCard(index) {
      this.$emit('remove', { index });
    },
  },
};
</script>

<style lang="scss" scoped>
  .card {
    position: relative;
    width: 280px;
    padding: 16px;

    background: $white;
    border-radius: 8px;

    @media (max-width: $mobile) {
      width: 100%;
    }

    .absolute_times {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
    }

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: $black;
      margin-bottom: 12px;

      @media (max-width: $mobile) {
        text-align: left;
      }
    }

    .form-card {
      input {
        padding: 13px;

        &::placeholder {
          font-size: 14px;
        }
      }

      @media (max-width: $mobile) {
        text-align: left;

        input {
          @media (max-width: $mobile) {
            margin-top: 8px;
            padding-left: 45px;

            &#name {
              background: $grey url("../../assets/icons/user.svg") 12px center no-repeat;
            }

            &#phone {
              background: $grey url("../../assets/icons/phone-2.svg") 12px center no-repeat;
            }

          }
        }
      }
    }

    .form-label {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.01em;

      margin-bottom: 4px;
      color: $black;
    }

    .form-control {
      width: 100%;
      padding: 16px;
      margin-bottom: 12px;

      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;

      color: $black;
      background: $grey;

      border: none;
      box-sizing: border-box;
      border-radius: 8px;

      transition: 0.4s;

      &::placeholder {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;

        color: rgba(30, 30, 30, 0.3);
      }

      &:focus {
        border: 1px solid $main;
        outline: none;
        color: rgb(30, 30, 30);
      }
    }
  }

  .error {
    font-size: 14px;
    color: red;
    margin-top: -10px;
  }
</style>